import React, { useContext, useEffect } from 'react'
import {
  Col, Form, Radio, RadioChangeEvent, Row, Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import TransactionFeeDetail from 'src/components/Transaction/TransactionFeeDetail'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import { INTERNATIONAL_CAR_REG } from 'src/utils/constants'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'
import { SellerProvideInfo } from 'src/views/new-transaction/new-transaction-needed-information/seller-provide-info'

import './style.scss'

interface NewTransactionFeeInfoProps {
  userType: EnumUserType,
  hasBeenShared?: boolean,
  shareTransactionId?: string | null
}

export default function NewTransactionFeeInfo({
  userType
}: NewTransactionFeeInfoProps) {
  // Validation fields
  const [form] = Form.useForm()

  const { navigateToStep, hasBeenShared } = useNewTransaction()
  const globalStore = useContext(store)
  const { role: userRole } = transactionUserInfo(userType, globalStore)

  const onChangeNumRegYears = (evt: RadioChangeEvent) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_NUM_REG_YEARS',
      numRegYears: evt.target.value
    })
  }
  const skipDMV = globalStore.state.createTransaction.vehicle.registrationState !== 'Vermont'

  // Skip this page if skipDMV is true
  useEffect(() => {
    if (globalStore.state.createTransaction.vehicle.registrationState !== 'Vermont') {
      navigateToStep({ step: EnumNewTransactionParams.Complete })
    }
  }, [navigateToStep])

  // const onChangePrevRegOrTaxes = (evt: RadioChangeEvent) => {
  //   globalStore.dispatch({
  //     type: 'UPDATE_SELF_TRANSFER_PREVIOUS_REG_OR_TAXES',
  //     prevRegOrTaxes: evt.target.value
  //   })
  // }

  const handleSubmit = async () => {
    try {
      const res = await form.validateFields()
      if (res) {
        navigateToStep({ step: EnumNewTransactionParams.Complete })
      }
    } catch (e) {
      // Ignore
    }
  }

  return (
    skipDMV ? null : (
      <Row className="new-transaction-fee-info">

        <Col span={20} offset={2}>
          {/* { */}
          {/*  userRole !== EnumUserRole.SelfTransfer && ( */}
          {/*    <div className="pass-the-device-note"> */}
          {/*      At this time, please return the device to the buyer */}
          {/*    </div> */}
          {/*  ) */}
          {/* } */}
          {
            !hasBeenShared && (
              <div className="pass-the-device-note">
                At this time, please return the device to the buyer
              </div>
            )
          }
          <SellerProvideInfo
            vehicleAge={globalStore.state.createTransaction.vehicle.year!}
            registeredIntl={globalStore.state.createTransaction.vehicle.registrationState === INTERNATIONAL_CAR_REG}
            originallyIntl={!!globalStore.state.createTransaction.vehicle.originallyInternational}
          />
          <Form form={form} onFinish={handleSubmit}>
            <Typography.Title
              level={5}
              className="fee-info-page__duration-title"
            >
              Registration duration
            </Typography.Title>
            <Typography.Text className="fee-info-page__duration-description">
              Vermont offers registrations for up to two years before it needs
              to be renewed
            </Typography.Text>
            <Spacer space={20} />
            <Form.Item
              name="numRegYears"
              initialValue={globalStore.state.createTransaction.vehicle.numRegYears}
              rules={[
                { required: true, message: 'Please select one option' }
              ]}
            >
              <Radio.Group
                className="fee-info-page__num-reg-years"
                onChange={onChangeNumRegYears}
              >
                <Radio value={1}>One year</Radio>
                <Spacer space={16} />
                <Radio value={2}>Two years</Radio>
              </Radio.Group>
            </Form.Item>
            {/* { */}
            {/*  userRole === EnumUserRole.SelfTransfer && ( */}
            {/*    <> */}
            {/*      <Typography.Title level={5} className="fee-info-page__duration-title"> */}
            {/*        Previous Registration or Paid Taxes */}
            {/*      </Typography.Title> */}
            {/*      <Typography.Text className="fee-info-page__duration-description"> */}
            {/*        Do you have proof that your vehicle has been registered in your */}
            {/*        name for at least three years, or you have already paid tax on */}
            {/*        this vehicle to the state that it is currently registered with? */}
            {/*      </Typography.Text> */}
            {/*      <Spacer space={20} /> */}
            {/*      <Form.Item */}
            {/*        name="prevRegOrTaxes" */}
            {/*        initialValue={globalStore.state.createTransaction.vehicle.prevRegOrTaxes} */}
            {/*        rules={[{ */}
            {/*          validator(_: any, value: any) { */}
            {/*            if (value !== true && value !== false) { */}
            {/*              return Promise.reject(new Error('Please select one option')) */}
            {/*            } */}
            {/*            return Promise.resolve() */}
            {/*          } */}
            {/*        }]} */}
            {/*      > */}
            {/*        <Radio.Group */}
            {/*          className="fee-info-page__prev-reg-or-taxes" */}
            {/*          onChange={onChangePrevRegOrTaxes} */}
            {/*        > */}
            {/*          <Radio value={true}>Yes</Radio> */}
            {/*          <Spacer space={16} /> */}
            {/*          <Radio value={false}>No</Radio> */}
            {/*        </Radio.Group> */}
            {/*      </Form.Item> */}
            {/*    </> */}
            {/*  ) */}
            {/* } */}
          </Form>
        </Col>
        {
          !!globalStore.state.createTransaction.vehicle.numRegYears && (
            <Col span={20} offset={2}>
              <hr className="transaction-fee__divider" />
            </Col>
          )
        }
        {
          !!globalStore.state.createTransaction.vehicle.numRegYears && (
            <TransactionFeeDetail vehicleInfo={globalStore.state.createTransaction.vehicle} />
          )
        }
        <Col className="new-transaction-fee-info__actions" span={20} offset={2}>
          <Spacer space={36} />
          <Button
            className="new-transaction-fee-info__continue-btn"
            type="primary"
            color="primary"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Col>
      </Row>
    )
  )
}
