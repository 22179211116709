import React, { useContext, useMemo, useState } from 'react'
import {
  Col,
  Row,
  Typography
} from 'antd'
import { isEqual } from 'lodash-es'

import { Button } from 'src/components/Button'
import { Spacer } from 'src/components/Spacer'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import {
  defaultCreateTransactionState
} from 'src/store/reducers/createTransactionReducer'
import { store } from 'src/store/store'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import './styles.scss'

const { Title } = Typography

export default function NewTransactionUserRoles() {
  const { navigateToStep } = useNewTransaction()
  // const [showSelfTransferModal, setShowSelfTransferModal] = useState(false)
  const globalStore = useContext(store)
  const initUserType = EnumUserType.Main
  const hasPendingCreatedTxn = useMemo(
    () => !isEqual(globalStore.state.createTransaction, defaultCreateTransactionState),
    [globalStore.state.createTransaction]
  )

  const navigateToPaymentTransferInformation = () => {
    navigateToStep({ step: EnumNewTransactionParams.ServicesSelection, userType: initUserType })
  }

  // const handleContinueSelfTransfer = () => {
  //   globalStore.dispatch({
  //     type: 'UPDATE_MAIN_USER_ROLE',
  //     role: EnumUserRole.SelfTransfer
  //   })
  //   globalStore.dispatch({
  //     type: 'UPDATE_SECONDARY_USER_ROLE',
  //     role: EnumUserRole.SelfTransfer
  //   })
  //   navigateToUserInformation()
  // }

  return (
    <Row className="new-transaction-user-role-page">
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Title
          className="new-transaction-user-role-page__title"
          level={4}
        >
          What is your role in this transaction?
        </Title>
        <Spacer space={60} />
        <Button
          color="primary"
          className="transaction-form__btn new-transaction-user-role-page__button"
          onClick={() => {
            globalStore.dispatch({
              type: 'UPDATE_MAIN_USER_ROLE',
              role: EnumUserRole.Buyer
            })
            globalStore.dispatch({
              type: 'UPDATE_SECONDARY_USER_ROLE',
              role: EnumUserRole.Seller
            })
            navigateToPaymentTransferInformation()
          }}
        >
          Buyer
        </Button>
        <Button
          color="success"
          className="transaction-form__btn new-transaction-user-role-page__button"
          onClick={() => {
            globalStore.dispatch({
              type: 'UPDATE_MAIN_USER_ROLE',
              role: EnumUserRole.Seller
            })
            globalStore.dispatch({
              type: 'UPDATE_SECONDARY_USER_ROLE',
              role: EnumUserRole.Buyer
            })
            navigateToPaymentTransferInformation()
          }}
        >
          Seller
        </Button>
        {/* <Button */}
        {/*  className="transaction-form__btn new-transaction-user-role-page__button" */}
        {/*  color="tertiary" */}
        {/*  onClick={() => setShowSelfTransferModal(true)} */}
        {/* > */}
        {/*  Self-Transfer */}
        {/* </Button> */}
        {
          hasPendingCreatedTxn
            && (
              <>
                <Spacer space={50} />
                <Button
                  color="danger"
                  className="transaction-form__btn new-transaction-user-role-page__button"
                  onClick={() => {
                    globalStore.dispatch({
                      type: 'RESET_TRANSACTION_STATE'
                    })
                  }}
                >
                  Start Over
                </Button>
              </>
            )
        }
        {/* <SelfTransferModal */}
        {/*  open={showSelfTransferModal} */}
        {/*  onCancel={() => setShowSelfTransferModal(false)} */}
        {/*  onOk={() => handleContinueSelfTransfer()} */}
        {/* /> */}
      </Col>
    </Row>
  )
}
