import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Alert, Col,
  Form, Typography
} from 'antd'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

import { HeaderLayout } from 'src/components/HeaderLayout'
import { Input } from 'src/components/Input'
import { getFirebaseAuth } from 'src/firebase/firebase'
import { EMAIL_REGEX } from 'src/utils/constants'

import { Button } from '../../components/Button'
// import { sendPasswordResetEmail } from 'firebase/auth'

export default function ForgotPassword() {
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const auth = getFirebaseAuth()
  const navigate = useNavigate()

  const handleSubmit = async ({ email }: { email: string; }) => {
    try {
      setError('')
      setLoading(true)
      await sendPasswordResetEmail(auth, email)
      setMessage('Check your inbox for password reset instructions')
    } catch (error) {
      setError('Failed to reset password')
    }
    setLoading(false)
  }

  return (
    <HeaderLayout title="Forgot Password" hasGoBack>
      <Col span={20} offset={2}>
        <div className="card">
          <div className="card-body">
            <h2 className="text-center mb-4">Password Reset</h2>

            <Form
              className="sign-in-form kuaay-form"
              name="signin"
              initialValues={{ email: '', password: '' }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your Email' },
                  { pattern: EMAIL_REGEX, message: 'Incorrect email format' }
                ]}
              >

                <Input
                  className="kuaay-input-v2"
                  placeholder="Email"
                  autoCapitalize="none"
                  type="email"
                  inputMode="email"
                />
              </Form.Item>

              <Button disabled={loading} className="reset-button" type="primary" htmlType="submit" style={{ display: 'block', margin: '0 auto' }}>
                Reset Password
              </Button>
              <Button onClick={() => navigate('/sign-in')} type="primary" style={{ display: 'block', margin: '1rem auto' }}>
                Back to Login
              </Button>
            </Form>
          </div>
        </div>
      </Col>
    </HeaderLayout>
  )
}
