import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  Col, Form, Modal, Row, Typography
} from 'antd'
import classNames from 'classnames'

import { Button } from 'src/components/Button'
import { InfoModal } from 'src/components/Modal'
import { Spacer } from 'src/components/Spacer'
import { Switch } from 'src/components/Switch'
import { useDisplay } from 'src/hooks/useDisplay'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams, EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'

import './styles.scss'

const { Text } = Typography

enum ServiceType {
  DOCUMENTS = 'documents',
  PAYMENT_TRANSFER = 'payment_transfer',
  PAYMENT_PROTECTION = 'payment_protection',
  SHIPMENT = 'shipment',
  BUYER_PAY_FOR_FEES = 'buyer_pay_for_fees',
  WARRANTY= 'warranty'
}

export default function NewTransactionServicesSelection() {
  const { navigateToStep, isSeller } = useNewTransaction()
  const navigate = useNavigate()
  const globalStore = useContext(store)
  const [form] = Form.useForm()

  const [showInfoModal, setShowInfoModal] = useState(false)
  const { smallModalWidth, largeModalWidth } = useDisplay()
  const [infoText, setInfoText] = useState('')
  const [infoTitle, setInfoTitle] = useState('')
  const [showPaymentProtectionReminder, setShowPaymentProtectionReminder] = useState(false)
  const [showOptionSelectedConfirmation, setShowOptionSelectedConfirmation] = useState(false)
  const [paymentProtectionReminderDisabled, setPaymentProtectionReminderDisabled] = React.useState(false)
  const [paymentProtectionPromptDisabled, setPaymentProtectionPromptDisabled] = React.useState(false)

  const {
    documentEnabled,
    shipment,
    warranty,
    paymentTransfer,
    askBuyerPayForTheFees
  } = globalStore.state.createTransaction

  const {
    enabled: paymentTransferEnabled,
    protectionEnabled
  } = paymentTransfer

  const {
    enabled: shipmentEnabled
  } = shipment

  const {
    enabled: warrantyEnabled
  } = warranty

  useEffect(() => {
    setPaymentProtectionPromptDisabled(localStorage.getItem('paymentProtectionPromptDisabled') === 'true')
    setPaymentProtectionReminderDisabled(localStorage.getItem('paymentProtectionReminderDisabled') === 'true')
  }, [])

  const handleSubmit = async () => {
    navigateToStep({ step: EnumNewTransactionParams.UserInformation })
  }

  const onUsePaymentTransferSwitchChange = () => {
    globalStore.dispatch({
      type: 'UPDATE_PAYMENT_TRANSFER_ENABLED',
      enabled: !paymentTransferEnabled
    })
    globalStore.dispatch({
      type: 'UPDATE_PAYMENT_TRANSFER_PROTECTION_ENABLED',
      protectionEnabled: false
    })
  }

  const onUseDocumentsSwitchChange = () => {
    globalStore.dispatch({
      type: 'UPDATE_CREATE_DOCUMENT_ENABLED',
      enabled: !documentEnabled
    })
  }

  const handleClickQuestionIcon = (evt: React.MouseEvent<HTMLSpanElement>, serviceType: ServiceType) => {
    evt.preventDefault()
    evt.stopPropagation()
    const textArr = []

    switch (serviceType) {
    case ServiceType.DOCUMENTS:
      textArr.push('Pacer will help you to prepare the documents for your vehicle.')
      setInfoTitle('About Create Documents')
      break
    case ServiceType.PAYMENT_TRANSFER:
      textArr.push('Buyer will transfer the vehicle price to Pacer bank account and then Pacer will release the funds to Seller.')
      setInfoTitle('About Digital Payment')
      break
    case ServiceType.PAYMENT_PROTECTION:
      textArr.push(
        'Pacer’s payment protection protects buyers in the event of an undisclosed issue.',
        '',
        'When a payment is sent to the seller, Pacer holds the funds until the vehicle is delivered. If the vehicle arrives as described, the funds are released by Pacer to the seller.',
        '',
        'In the event of an undisclosed issue, Pacer will work with both buyer and seller to resolve any disputes.'
      )
      setInfoTitle("About Pacer's Payment Protection")
      break
    case ServiceType.SHIPMENT:
      textArr.push('After paying for the documents fee, you will be redirected to New Shipping form to get a shipping quote for the vehicle that you have filled in.')
      setInfoTitle("About Pacer's Shipping service")
      break
    case ServiceType.BUYER_PAY_FOR_FEES:
      setInfoTitle('')
      textArr.push('If you initialize the transaction as a Seller, you can request the Buyer to pay for all the fees.')
      break
    case ServiceType.WARRANTY:
      setInfoTitle('')
      textArr.push('Fair will help you to purchase a warranty for your vehicle.')
      break
    default:
      break
    }
    setInfoText(textArr.join('\n'))
    setShowInfoModal(true)
  }

  const onUseShipmentSwitchChange = () => {
    globalStore.dispatch({
      type: 'UPDATE_SHIPMENT_ENABLED',
      enabled: !shipmentEnabled
    })
  }

  const onUseWarrantySwitchChange = () => {
    globalStore.dispatch({
      type: 'UPDATE_WARRANTY_ENABLED',
      enabled: !warrantyEnabled
    })
  }

  const onAskBuyerPayFeesSwitchChange = () => {
    globalStore.dispatch({
      type: 'UPDATE_ASK_BUYER_PAY_FOR_THE_FEES',
      askBuyerPayForTheFees: !askBuyerPayForTheFees
    })
  }

  const handleClickContinue = () => {
    if (shipmentEnabled && !documentEnabled && !paymentTransferEnabled) {
      navigate(EnumRoute.NewShipping)
    } else if (paymentTransferEnabled && !protectionEnabled && !paymentProtectionReminderDisabled) {
      setShowPaymentProtectionReminder(true)
    } else if (isSeller) {
      setShowOptionSelectedConfirmation(true)
    // } else if (warrantyEnabled && !documentEnabled && !paymentTransferEnabled) {
    //   navigate(EnumRoute.Warranty)
    } else {
      handleSubmit()
    }
  }

  const disablePaymentProtectionPrompt = () => {
    setPaymentProtectionPromptDisabled(true)
    localStorage.setItem('paymentProtectionPromptDisabled', 'true')
  }

  const displayPaymentProtectionPopup = () => {
    if (paymentProtectionPromptDisabled) {
      return
    }
    setInfoTitle('')
    setInfoText(
      [
        'You have selected to use Payment Protection.\n',
        'Payment protection is typically used when you are buying a car sight-unseen to protect yourself in the event of undisclosed issues.\n',
        'When selected, the seller will be prompted to enter all known issues to you for you to accept.\n'
      ].join('\n')
    )
    setShowInfoModal(true)
    disablePaymentProtectionPrompt()
  }

  const onUsePaymentProtectSwitchChange = () => {
    if (!protectionEnabled) {
      setTimeout(() => {
        displayPaymentProtectionPopup()
      }, 200)
    }
    globalStore.dispatch({
      type: 'UPDATE_PAYMENT_TRANSFER_PROTECTION_ENABLED',
      protectionEnabled: !protectionEnabled
    })
  }

  const disablePaymentProtectionReminder = () => {
    setPaymentProtectionReminderDisabled(true)
    localStorage.setItem('paymentProtectionReminderDisabled', 'true')
  }

  const submitForm = () => {
    setShowInfoModal(false)
    setShowPaymentProtectionReminder(false)
    setShowOptionSelectedConfirmation(false)
    form.submit()
  }

  return (
    <Row className={classNames('payment-transfer-info')}>
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <InfoModal
          title={infoTitle}
          text={infoText}
          open={showInfoModal}
          width={smallModalWidth}
          onClose={() => setShowInfoModal(false)}
        />
        <Modal
          open={showPaymentProtectionReminder}
          closable={false}
          className="payment-protection-modal"
          width={largeModalWidth}
          centered
          footer={null}
        >
          <div className="payment-protection-modal__body">
            <div>
              Payment Protection is not selected.
            </div>
            <br />
            <div>
              Payment Protection is typically used when you are buying a car sight-unseen
              to protect yourself in the event of undisclosed issues.
            </div>
            <br />
            <div>
              Are you sure you&apos;d like to continue without Payment Protection?
            </div>
          </div>
          <div className="payment-protection-modal__actions">
            <Button
              className="transaction-form__btn"
              type="primary"
              size="small"
              color="default"
              onClick={() => {
                setShowPaymentProtectionReminder(false)
                disablePaymentProtectionPrompt()
                disablePaymentProtectionReminder()
              }}
            >
              Back
            </Button>
            <Button
              className="transaction-form__btn"
              type="primary"
              size="small"
              color="primary"
              onClick={() => {
                setShowPaymentProtectionReminder(false)
                if (isSeller) {
                  setShowOptionSelectedConfirmation(true)
                } else {
                  handleSubmit()
                }
                disablePaymentProtectionReminder()
              }}
            >
              Continue without Payment Protection
            </Button>
          </div>
        </Modal>
        <Modal
          open={showOptionSelectedConfirmation}
          closable={false}
          className="payment-protection-modal"
          width={largeModalWidth}
          centered
          footer={null}
        >
          <div className="payment-protection-modal__body">
            <div>
              Before continuing, confirm the options selected with the buyer since it will impact their transaction.
            </div>
          </div>
          <div className="payment-protection-modal__actions">
            <Button
              className="transaction-form__btn"
              type="primary"
              size="small"
              onClick={() => {
                setShowOptionSelectedConfirmation(false)
              }}
            >
              Back
            </Button>
            <Button
              className="transaction-form__btn"
              type="primary"
              size="small"
              color="primary"
              onClick={submitForm}
            >
              I Confirm
            </Button>
          </div>
        </Modal>
        <Spacer space={24} />
        <Form
          className="payment-transfer-info__form kuaay-form"
          onFinish={handleSubmit}
          form={form}
        >
          <div className="payment-transfer-info__form-switch-field">
            <Switch
              className="payment-transfer-info__form-switch-btn"
              checked={documentEnabled}
              onChange={onUseDocumentsSwitchChange}
            />
            <div className="payment-transfer-info__switch-content" onClick={onUseDocumentsSwitchChange}>
              <Text className="payment-transfer-info__form-switch-label">
                Create Documents
              </Text>
              <QuestionCircleOutlined
                color="white"
                className="payment-transfer-info__question-icon"
                onClick={(evt) => handleClickQuestionIcon(evt, ServiceType.DOCUMENTS)}
              />
            </div>
          </div>
          <Spacer space={24} />
          <div className="payment-transfer-info__form-switch-field">
            <Switch
              className="payment-transfer-info__form-switch-btn"
              checked={paymentTransferEnabled}
              onChange={onUsePaymentTransferSwitchChange}
            />

            <div className="payment-transfer-info__switch-content" onClick={onUsePaymentTransferSwitchChange}>
              <Text className="payment-transfer-info__form-switch-label">
                Pay here digitally
              </Text>
              <QuestionCircleOutlined
                color="white"
                className="payment-transfer-info__question-icon"
                onClick={(evt) => handleClickQuestionIcon(evt, ServiceType.PAYMENT_TRANSFER)}
              />
            </div>
          </div>
          <Spacer space={24} />
          {paymentTransferEnabled && (
            <div className="payment-transfer-info__form-switch-field">
              <Switch
                className="payment-transfer-info__form-switch-btn"
                checked={protectionEnabled}
                onChange={onUsePaymentProtectSwitchChange}
              />
              <div className="payment-transfer-info__switch-content" onClick={onUsePaymentProtectSwitchChange}>
                <Text className="payment-transfer-info__form-switch-label">
                  Use Pacer Payment Protection (for online transactions)
                </Text>
                <QuestionCircleOutlined
                  color="white"
                  className="payment-transfer-info__question-icon"
                  onClick={(evt) => handleClickQuestionIcon(evt, ServiceType.PAYMENT_PROTECTION)}
                />
              </div>
            </div>
          )}
          <Spacer space={24} />
          <div className="payment-transfer-info__form-switch-field">
            <Switch
              className="payment-transfer-info__form-switch-btn"
              checked={shipmentEnabled}
              onChange={onUseShipmentSwitchChange}
            />
            <div className="payment-transfer-info__switch-content" onClick={onUseShipmentSwitchChange}>
              <Text className="payment-transfer-info__form-switch-label">
                Use Pacer Shipping service
              </Text>
              <QuestionCircleOutlined
                color="white"
                className="payment-transfer-info__question-icon"
                onClick={(evt) => handleClickQuestionIcon(evt, ServiceType.SHIPMENT)}
              />
            </div>
          </div>

          <Spacer space={24} />
          {/* <div className="payment-transfer-info__form-switch-field">
            <Switch
              className="payment-transfer-info__form-switch-btn"
              checked={warrantyEnabled}
              onChange={onUseWarrantySwitchChange}
            />
            <div className="payment-transfer-info__switch-content" onClick={onUseWarrantySwitchChange}>
              <Text className="payment-transfer-info__form-switch-label">
                Use Fair Warranty service
              </Text>
              <QuestionCircleOutlined
                color="white"
                className="payment-transfer-info__question-icon"
                onClick={(evt) => handleClickQuestionIcon(evt, ServiceType.WARRANTY)}
              />
            </div>
          </div> */}
          {
            isSeller && (
              <>

                <Spacer space={24} />
                <div className="payment-transfer-info__form-switch-field">
                  <Switch
                    className="payment-transfer-info__form-switch-btn"
                    checked={askBuyerPayForTheFees}
                    onChange={onAskBuyerPayFeesSwitchChange}
                  />
                  <div className="payment-transfer-info__switch-content" onClick={onAskBuyerPayFeesSwitchChange}>
                    <Text className="payment-transfer-info__form-switch-label">
                      Let Buyer pay for the fees
                    </Text>
                    <QuestionCircleOutlined
                      color="white"
                      className="payment-transfer-info__question-icon"
                      onClick={(evt) => handleClickQuestionIcon(evt, ServiceType.BUYER_PAY_FOR_FEES)}
                    />
                  </div>
                </div>
              </>
            )
          }
        </Form>
        <Spacer className="flex-grow-1" space={24} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={handleClickContinue}
          // disabled={!documentEnabled && !paymentTransferEnabled && !shipmentEnabled && !warrantyEnabled}
          disabled={!documentEnabled && !paymentTransferEnabled && !shipmentEnabled}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}
