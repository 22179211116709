import { EnumUserRole, ShareStep } from 'src/utils/types'

import { PaymentTransferHistory, PaymentTransferInfo } from './paymentTransfer'

export type ShareRole = 'sender' | 'receiver'

// export type UserRole = 'buyer' | 'seller' | 'selfTransfer' | undefined
export type UserRole = 'buyer' | 'seller' | undefined

export type Sex = 'Male' | 'Female' | undefined

export type VehicleType = 'Car/SUV' | 'Motorcycle' | 'Motorhome' | undefined // Not currently allowed: "Truck"

export type VehicleBodyType =
  | 'SUV'
  | 'Sedan'
  | 'Pickup'
  | 'Van'
  | 'Convertible'
  | 'Wagon'
  | 'Hatchback'
  | 'Coupe'
  | undefined

export type BrakeType = 'Hydraulic' | 'Air' | 'Other' | undefined

export type FuelType =
  | 'Gas'
  | 'Diesel'
  | 'Propane'
  | 'Hybrid'
  | 'Electric'
  | 'Other'
  | undefined

export type ConditionType = 'New' | 'Used' | 'Rebuilt' | undefined

export type SalvageType =
  | 'Salvage'
  | 'Salvage and Rebuilt'
  | 'Declared total loss by insurer'
  | 'None/Not Applicable'
  | undefined

export type VehicleLengthUnit = 'inches' | 'cm'

export type OdometerUnit = 'miles' | 'kilometers' | 'hours'

export type DisclosureType =
  | 'Reflects the actual mileage'
  | 'Reflects the mileage in excess of the odometer’s mechanical limits'
  | 'Is not the actual mileage - Odometer Discrepancy'
export type NumRegyears = 1 | 2

export interface PDFInfo {
  mainUser: PersonalInfo;
  secondaryUser?: PersonalInfo;
  vehicle: VehicleInfo;
}

enum TransactionDocumentStatus {
  PREPARING = 'PREPARING',
  COMPLETED = 'COMPLETED'
}

export interface TransactionDocument {
  enabled: boolean
  link?: string
  isCompleted?: boolean
}

export interface TransactionInfoBase extends PDFInfo {
  transactionId: string;
  userId: string;
  stripeTransactionId?: string;
  // List of user IDs that this transaction has been shared with
  sharedWith?: string[];
  partial?: boolean;
  // Whether or not the transaction can be editted after the fact
  // Default: False (due to not existing)
  editable?: boolean;
  shipment?: TxnShipment
  warranty?: WarrantyState
  document?: TransactionDocument
  paymentTransfer?: PaymentTransferInfo
  paymentTransferHistories?: PaymentTransferHistory[]
  askBuyerPayForTheFees: boolean
  documentEnabled: boolean
}

export interface TransactionInfo extends TransactionInfoBase {
  creationDate: Date;
}

export interface TransactionInfoRaw extends TransactionInfoBase {
  creationDate?: string;
}

export type PersonalInfo = {
  isBusiness?: boolean
  diffMailingAddress?: boolean
  authorizedRepTitle?: string
  firstName: string
  middleName: string
  lastName: string
  role: EnumUserRole | undefined
  sex?: Sex
  dateOfBirth?: Date
  license: string
  address?: string
  address2?: string
  state?: string
  city?: string
  zip?: number | string
  addressMailing?: string
  addressMailing2?: string
  stateMailing?: string
  cityMailing?: string
  zipMailing?: number | string
  phone?: string
  email?: string
  // Base64 string of signature image
  signature?: string
}

export type VehicleInfo = {
  year: number | null
  salePrice?: number
  nadaValue?: number
  type?: VehicleType
  registrationState: string | null
  originallyInternational?: boolean
  vin: string
  make: string
  model: string
  bodyType?: VehicleBodyType
  numAxles?: number
  brakeType?: BrakeType
  fuelType?: FuelType
  condition?: ConditionType
  salvage?: SalvageType
  emissionExtension: boolean
  numWheels?: number
  cubicCentimeters?: number
  color?: string
  vehicleLengthUnit: VehicleLengthUnit
  vehicleLength?: number
  odometerUnit: OdometerUnit
  odometerReading?: number
  odometerDisclosure?: DisclosureType
  purchaseDate?: Date
  numCylinders?: number
  numRegYears?: NumRegyears
  // Vermont infos
  vtRegNumber?: string
  vtTitleNumber?: string
  vtExpirationDate?: Date
  vtPlate?: string
  prevRegOrTaxes?: boolean
}

export interface TxnShipment {
  enabled: boolean
  shipmentId?: string
}

export interface WarrantyState {
  enabled: boolean
}

export enum EnumMailingAddress {
  SameMailing = 'SameMailing',
  DifferenceMailing = 'DifferenceMailing'
}

export interface PendingTransactionInfo extends TransactionInfo {
  step?: ShareStep;
  sharingId?: string;
  shareRole?: ShareRole;
}
