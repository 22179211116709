import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react'
import { Col, Row, Typography } from 'antd'

import { Button } from 'src/components/Button'
import { ShipmentDetail } from 'src/components/Shipment/ShipmentDetail'
import { Spacer } from 'src/components/Spacer'
import { useNewShipment } from 'src/hooks/useNewShipment'
// import { useNewWarranty } from 'src/hooks/useNewWarranty'
import { EnumNewShipmentStep, EnumNewWarrantyStep } from 'src/router/types'
import { store } from 'src/store/store'
import { ShipmentInfo } from 'src/types/shipments'
import { TransactionInfo, TransactionInfoRaw } from 'src/types/transactions'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { transactionRawToTransactionInfo } from 'src/utils/transaction'

import './NewShipmentOrderConfirm.scss'

export const NewShipmentOrderConfirm = () => {
  const { resetNewShipmentState, navigateToStep } = useNewShipment()
  // const { navigateToWarStep } = useNewWarranty()
  const [transaction, setTransaction] = useState<TransactionInfo | null>(null)
  const countdownRef = useRef<number>(5)
  const [countdownNum, setCountdownNum] = useState<number>(5)
  const timeoutRef = useRef<number|null>(null)
  const globalStore = useContext(store)

  // if warranty enabled nav to warranty
  // useEffect(() => {
  //   // if (transaction?.transactionId && transaction.warranty?.enabled) {
  //   //   const tmpTimeoutId = setInterval(() => {
  //   //     if (countdownRef.current <= 0) {
  //   //       // resetNewWarrantyState()
  //   //       // navigateToWarStep({
  //   //       //   step: EnumNewWarrantyStep.MainInfo,
  //   //       //   transactionId: globalStore.state.createTransaction.transactionId
  //   //       // })
  //   //       if (timeoutRef.current) {
  //   //         clearInterval(timeoutRef.current)
  //   //       }
  //   //     } else {
  //   //       countdownRef.current -= 1
  //   //       setCountdownNum(countdownRef.current)
  //   //     }
  //   //   }, 1000)
  //   //   timeoutRef.current = tmpTimeoutId as unknown as number
  //   // }

  //   return () => {
  //     if (timeoutRef.current) {
  //       clearInterval(timeoutRef.current)
  //     }
  //   }
  // }, [transaction])

  useEffect(() => {
    if (globalStore.state.createTransaction.transactionId) {
      authApiAxiosWithRefresh()
        .then((axiosInst) => axiosInst.get(
          `/transaction/retrieve/${globalStore.state.createTransaction.transactionId}`
        ))
        .then((res) => {
          setTransaction(transactionRawToTransactionInfo(res.data as TransactionInfoRaw))
        })
    }
  }, [globalStore.state.createTransaction.transactionId])

  const {
    createShipment
  } = globalStore.state

  useEffect(() => {
    if (!createShipment.complete) {
      navigateToStep({ step: EnumNewShipmentStep.MainInfo })
    }

    return () => {
      resetNewShipmentState()
    }
  }, [])
  const shipment = useMemo(() => ({
    shipmentId: createShipment.shipmentId,
    shippingDate: createShipment.shippingDate,
    pickupAddress: createShipment.pickupAddress,
    pickupContact: createShipment.pickupContact,
    deliveryAddress: createShipment.deliveryAddress,
    deliveryContact: createShipment.deliveryContact,
    vehicles: createShipment.vehicles,
    price: createShipment.price,
    creationDate: new Date(),
    complete: createShipment.complete
  } as unknown as ShipmentInfo), [createShipment])

  useEffect(() => {
    initialize()
  }, [])

  const initialize = async () => {

  }

  const handleClickNewQuote = () => {
    resetNewShipmentState()
    navigateToStep({ step: EnumNewShipmentStep.MainInfo })
  }

  return (
    <Row className="new-shipment-order-confirm-page">
      <Col className="new-shipment-order-confirm-page__content-wrapper" span={20} offset={2}>
        {/* {
          !!transaction?.transactionId && transaction.warranty?.enabled && (
            <>
              <Spacer space={12} />
              <div className="new-transaction-order-confirmation__redirect-note">
                You will be redirected to warranty in
                {' '}
                { countdownNum }
                {' '}
                seconds...
              </div>
              <Spacer space={12} />
            </>
          )
        } */}
        <Typography.Title level={4} className="new-shipment-order-confirm-page__title">
          <div>Your shipping request</div>
          <div>is being processed</div>
        </Typography.Title>
        { shipment.complete && <ShipmentDetail shipment={shipment} /> }
        <Spacer space={24} />
        <div className="d-flex flex-col align-center new-shipment-order-confirm-page__actions">
          <Button
            type="primary"
            color="info"
            className="start-new-quote-btn"
            onClick={handleClickNewQuote}
          >
            Start a new quote
          </Button>
        </div>
      </Col>
    </Row>
  )
}
