import { useEffect } from 'react'

declare function initializeFinancingForm(clientId: string): void;

const Financing: React.FC = () => {
  useEffect(() => {
    initializeFinancingForm('2FGloDg3MJYm2orjo1mQOG5cPuy1')
  }, [])

  return (
    <div id="financing-form-container" style={{ marginTop: '2rem' }} />
  )
}

export default Financing
