import React, { useContext, useMemo } from 'react'
import {
  Col, Form, Row, Typography
} from 'antd'
import classNames from 'classnames'

import { Button } from 'src/components/Button'
import { Checkbox } from 'src/components/Checkbox'
import { Input } from 'src/components/Input'
import { Spacer } from 'src/components/Spacer'
import { USStateSelect } from 'src/components/USStateSelect'
import { VehicleType } from 'src/components/VehicleType'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { EnumNewTransactionParams } from 'src/router/types'
import { store } from 'src/store/store'
import {
  INTERNATIONAL_CAR_REG, NUMBER_REGEX, VEHICLE_TYPE, YEAR_REGEX
} from 'src/utils/constants'
import { transactionUserInfo } from 'src/utils/storeHelpers'
import { EnumUserRole, EnumUserType } from 'src/utils/types'

import './styles.scss'

const { Title } = Typography
interface NewTransactionMainVehicleInfoProps {
  userType: EnumUserType
}

export default function NewTransactionMainVehicleInfo(
  { userType }: NewTransactionMainVehicleInfoProps
) {
  const { navigateToStep } = useNewTransaction()
  const globalStore = useContext(store)
  const { role: userRole } = transactionUserInfo(userType, globalStore)
  const [form] = Form.useForm()

  const {
    year,
    type,
    registrationState,
    originallyInternational,
    numWheels,
    cubicCentimeters
  } = globalStore.state.createTransaction.vehicle

  const handleSubmit = () => {
    if (userRole !== EnumUserRole.Buyer) {
      navigateToStep({ step: EnumNewTransactionParams.SpecificVehicleInfo })
    } else {
      navigateToStep({ step: EnumNewTransactionParams.NeededInfo })
    }
  }

  const onRegistrationInternationalChange = (e: { target: { checked: boolean } }) => {
    if (
      globalStore.state.createTransaction.vehicle
        .registrationState === INTERNATIONAL_CAR_REG
    ) {
      // Clear it lazily
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_REGISTRATION_STATE',
        registrationState: ''
      })

      // Mark it as not international originally since
      // we aren't sure and so err on the side of letting the user check it!
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_ORIGINALLY_INTERNATIONAL_STATE',
        originallyInternational: false
      })
      form.setFieldValue('registrationState', null)
      form.setFieldValue('originallyInternational', false)
    } else {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_REGISTRATION_STATE',
        registrationState: INTERNATIONAL_CAR_REG
      })
      // Mark it as international originally
      // since this is the only case this can happen with used cars!
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_ORIGINALLY_INTERNATIONAL_STATE',
        originallyInternational: true
      })
      form.setFieldValue('registrationState', INTERNATIONAL_CAR_REG)
      form.setFieldValue('originallyInternational', true)
    }
    form.validateFields(['registrationState'])
  }

  const onOriginalyInternationalChange = (e: { target: { checked: boolean } }) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_ORIGINALLY_INTERNATIONAL_STATE',
      originallyInternational: e.target.checked
    })
  }

  const registrationStateValidationRules = useMemo(
    () => (registrationState !== INTERNATIONAL_CAR_REG
      ? [{ required: true, message: 'Registration State is required' }] : []),
    [registrationState]
  )

  const yearCustomValidationRule = () => ({
    validator(_: any, value: any) {
      if (!value || `${value}`.trim() === '') {
        return Promise.reject(new Error('Year is required'))
      }
      if (
        YEAR_REGEX.test(value)
        && value <= new Date().getUTCFullYear()
        && value > 1886
      ) {
        globalStore.dispatch({
          type: 'UPDATE_VEHICLE_YEAR',
          year: value
        })
        return Promise.resolve()
      }
      return Promise.reject(new Error('Year is incorrect'))
    }
  })

  const updateCubicCentimeters = (value: any) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_CUBIC_CENTIMETERS',
      cubicCentimeters: value != null ? parseInt(value, 10) : undefined
    })
  }

  const updateNumberOfWheels = (value: any) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_NUM_WHEELS',
      numWheels: value != null ? parseInt(value, 10) : undefined
    })
  }

  const handleChangeVehicleType = (bodyType: string) => {
    globalStore.dispatch({
      type: 'UPDATE_VEHICLE_TYPE',
      bodyType
    })

    if (bodyType === VEHICLE_TYPE.Motorcycle) {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_BODY_TYPE',
        bodyType: `${VEHICLE_TYPE.Motorcycle} (MC)`
      })
    } else if (bodyType === VEHICLE_TYPE.MotorHome) {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_BODY_TYPE',
        bodyType: `${VEHICLE_TYPE.MotorHome} (MH)`
      })
      updateCubicCentimeters(undefined)
      updateNumberOfWheels(undefined)
      form.setFieldsValue({ numWheels: undefined, cubicCentimeters: undefined })
    } else {
      globalStore.dispatch({
        type: 'UPDATE_VEHICLE_BODY_TYPE',
        bodyType: undefined
      })
      updateCubicCentimeters(undefined)
      updateNumberOfWheels(undefined)
      form.setFieldsValue({ numWheels: undefined, cubicCentimeters: undefined })
    }
  }

  const renderMotorcycleInfo = () => {
    if (type === VEHICLE_TYPE.Motorcycle) {
      return (
        <>
          <Form.Item
            name="numWheels"
            rules={[
              { required: true, message: 'Number of Wheels is required' },
              { pattern: NUMBER_REGEX, message: 'Number of Wheels must be a positive number' }
            ]}
            initialValue={numWheels}
          >
            <Input
              placeholder="Number of Wheels"
              autoCapitalize="none"
              inputMode="numeric"
              type="number"
              onChange={(e) => {
                updateNumberOfWheels(e.target.value.replace(/\D/g, ''))
              }}
            />
          </Form.Item>
          <Form.Item
            name="cubicCentimeters"
            rules={[
              { required: true, message: 'Cubic Centimeters is required' },
              { pattern: NUMBER_REGEX, message: 'Cubic Centimeters must be a positive number' }
            ]}
            initialValue={cubicCentimeters}
          >
            <Input
              placeholder="Cubic Centimeters (CC)"
              autoCapitalize="none"
              inputMode="numeric"
              type="number"
              onChange={(e) => {
                updateCubicCentimeters(e.target.value.replace(/\D/g, ''))
              }}
            />
          </Form.Item>
        </>
      )
    }
    return null
  }

  const renderRegistrationState = () => {
    if (registrationState === INTERNATIONAL_CAR_REG) {
      return (
        <Form.Item
          name="registrationStatePlaceholder"
        >
          <USStateSelect
            placeholder="Current Registered State"
            disabled
          />
        </Form.Item>
      )
    }
    return (
      <Form.Item
        name="registrationState"
        rules={registrationStateValidationRules}
        initialValue={registrationState}
      >
        <USStateSelect
          placeholder="Current Registered State"
          disabled={registrationState === INTERNATIONAL_CAR_REG}
          onChange={(data) => {
            globalStore.dispatch({
              type: 'UPDATE_VEHICLE_REGISTRATION_STATE',
              registrationState: data
            })
          }}
        />
      </Form.Item>
    )
  }

  const vehicleInfoTitle = useMemo(() => `What is being ${
    userRole === EnumUserRole.Buyer
      ? 'purchased'
      : 'sold'
  }?`, [userRole])

  return (
    <Row className={classNames('main-vehicle-info')}>
      <Col className="transaction-form-wrapper flex-col" span={20} offset={2}>
        <Title className="main-vehicle-info__title" level={4}>
          {vehicleInfoTitle}
        </Title>
        <br />
        <Form
          className="main-vehicle-info__form kuaay-form"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item
            name="type"
            rules={[{ required: true, message: 'Vehicle Type is required' }]}
            initialValue={type}
          >
            <VehicleType
              onChange={handleChangeVehicleType}
              placeholder="Vehicle Type (Car/SUV, MC, MH)"
            />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="registrationInternational"
            initialValue={registrationState === INTERNATIONAL_CAR_REG}
            className="main-vehicle-info__form-checkbox-field"
          >
            <Checkbox
              onChange={onRegistrationInternationalChange}
            >
              Never registered in the US
            </Checkbox>
          </Form.Item>
          <Form.Item
            initialValue={originallyInternational}
            valuePropName="checked"
            name="originallyInternational"
            className="main-vehicle-info__form-checkbox-field"

          >
            <Checkbox
              onChange={onOriginalyInternationalChange}
            >
              Vehicle was originally internationally imported
            </Checkbox>
          </Form.Item>
          {
            renderMotorcycleInfo()
          }
        </Form>
        <Spacer className="flex-grow-1" space={4} />
        <Button
          className="transaction-form__btn"
          type="primary"
          color="primary"
          onClick={() => form.submit()}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}
