// PageStepper.tsx
import React from 'react'

import './PageStepper.scss'

interface PageStepperProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
}

const labels = ['Vin/Model', 'Features', 'Confirm', 'Generate']

const PageStepper: React.FC<PageStepperProps> = ({ currentPage, totalPages, setCurrentPage }) => {
  return (
    <div className="stepper">
      {[...Array(totalPages)].map((_, index) => (
        <div
          key={index}
          className={`step ${currentPage === index ? 'active' : ''}`}
          onClick={() => setCurrentPage(index)}
        >
          <div className="circle">{index + 1}</div>
          <div className="line-label-container">
            <div className="line" />
            <div className="label">{labels[index]}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default PageStepper
