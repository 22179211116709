import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Col, Form, Row, Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { Input } from 'src/components/Input'
import { Spacer } from 'src/components/Spacer'
// temp while all users can use tool
import { useAuthen } from 'src/hooks/useAuthen'
import { useDisplay } from 'src/hooks/useDisplay'
import { VerificationReport } from 'src/types/identity'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { retrieveVerifPdf } from 'src/utils/identityHelpers'

import './styles.scss'

const { Title, Paragraph, Text } = Typography

interface VerificationDetailsProps {
    orgName: string | null;
    dealerId: string | null;
    verificationId: string | null;
  }

const VerificationDetails: React.FC<VerificationDetailsProps> = ({ orgName, dealerId, verificationId }) => {
  const [session, setSession] = useState<VerificationReport | null>(null)
  const [fileUrls, setFileUrls] = useState<string[]>([])
  const [seshError, setSeshError] = useState<string | null>(null)

  const getVerificationDetails = async (orgName: string | null, dealerId: string | null, sessionId: string | null) => {
    try {
      const response = await (await authApiAxiosWithRefresh())
        .get(`stripe-identity/retrieve_verification_session/${dealerId}?sessionId=${sessionId}`)
      if (response.data.last_verification_report) {
        return response.data.last_verification_report
      }
      throw new Error('No verification report found')
    } catch (error) {
      setSeshError('Error retrieving verification details ')
      return null
    }
  }

  const createFileLinks = async (sessionObj: VerificationReport | null) => {
    if (sessionObj?.document?.files) {
      const fileLinks = await Promise.all(
        sessionObj.document.files.map(async (file) => {
          const link = await (await authApiAxiosWithRefresh()).get(`stripe-identity/retrieve_file_link/${file}`)
          return link.data
        })
      )

      setFileUrls(fileLinks)
    }
  }

  useEffect(() => {
    if (dealerId && verificationId) {
      getVerificationDetails(orgName, dealerId, verificationId).then((data: VerificationReport) => {
        setSession(data)
        createFileLinks(data)
      })
    }
  }, [dealerId && verificationId])

  if (session) {
    return (
      <Row className="main-verification-container">
        <Col xs={24} sm={24} className="verification-details">
          <Title className="header" type="secondary">Verification Details</Title>
          <Form className="main-vehicle-info__form kuaay-form">

            <Form.Item>
              <Input
                addonBefore={<span className="custom-input-group-addon">Name</span>}
                disabled
                className="custom-input"
                defaultValue={`${session.document.first_name} ${session.document.last_name}`}
              />
            </Form.Item>

            <Form.Item>
              <Input
                addonBefore={<span className="custom-input-group-addon">Document Type</span>}
                disabled
                className="custom-input"
                defaultValue={session.document.type || ''}
              />
            </Form.Item>

            <Form.Item>
              <Input
                addonBefore={<span className="custom-input-group-addon">Document Expiration Date</span>}
                disabled
                defaultValue={`${session.document.expiration_date?.month}/${session.document.expiration_date?.day}/${session.document.expiration_date?.year}` || ''}
              />
            </Form.Item>

            <Row>
              <Col xs={12}>
                <Form.Item className="custom-form-container">
                  <Input
                    addonBefore={<span className="custom-input-group-addon">Issuing Country</span>}
                    defaultValue={session.document.issuing_country || ''}
                  />
                </Form.Item>
              </Col>
              <Col offset={1} xs={12}>
                <Form.Item>
                  <Input
                    addonBefore={<span className="custom-input-group-addon">Issuing State</span>}
                    disabled
                    defaultValue={session.document.address?.state || ''}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Input
                addonBefore={<span className="custom-input-group-addon">Document Number</span>}
                disabled
                className="custom-input"
                defaultValue={session.document.number || ''}
              />
            </Form.Item>

            <Form.Item>
              <Input
                addonBefore={<span className="custom-input-group-addon">Verification Status</span>}
                disabled
                className="custom-input"
                defaultValue={session.document.status || ''}
              />
            </Form.Item>
          </Form>

          <div className="id_container">
            {fileUrls.map((url, index) => (<img key={index} src={url} alt={`Verification document ${index + 1}`} />))}
          </div>

          <Spacer space={24} />

          <Button
            className="transaction-form__btn"
            color="primary"
            type="primary"
            onClick={() => { retrieveVerifPdf(orgName, dealerId, verificationId) }}
          >
            Print to PDF
          </Button>
        </Col>
      </Row>
    )
  }

  if (seshError) {
    return (
      <Row className="main-verification-container">
        <Col xs={24} sm={24} className="verification-details">
          <Title className="header" type="secondary">Oops... we couldn&apos;t get your request</Title>
          <Paragraph>{seshError}</Paragraph>
        </Col>
      </Row>
    )
  }

  return null
}

const IdentityDetailsPage: React.FC = () => {
  const { orgName, verificationId } = useParams<{ orgName: string; verificationId: string }>()
  const nav = useNavigate()
  const { isMobileDevice } = useDisplay()
  const [dealerId, setDealerId] = useState<string | null>(null)
  const { currentUser } = useAuthen()

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await (await authApiAxiosWithRefresh()).get('user/orgData')
        setDealerId(response.data.id)
      } catch (error) {
        // temp while all users can use
        if (currentUser?.uid) {
          setDealerId(currentUser?.uid)
        }
        // const err = error as Error
        // console.error('Error fetching dealerId:', err)
      }
    }

    fetchOrgData()
  }, [])

  const title = 'Verification Details'
  const subtitle = 'View and Print Results'
  const renderRightItems = () => {
    if (!isMobileDevice) {
      return (
        <Text>
          Dealer:
          {orgName}
        </Text>
      )
    }
    return null
  }
  return (
    <>
      <Spacer space={15} />
      <HeaderLayout
        title={title}
        hasGoBack={true}
        subtitle={subtitle}
        rightItems={renderRightItems}
        onGoBack={() => nav(-1)}
      >
        <div className="verify-identity-details">
          {dealerId && verificationId && orgName && (
            <div>
              <VerificationDetails orgName={orgName} dealerId={dealerId} verificationId={verificationId} />
            </div>
          )}
        </div>
      </HeaderLayout>
    </>
  )
}

export default IdentityDetailsPage
