// File for all other types
export interface INotifcationData {
  route?: string;
  data?: {
    [key: string]: unknown;
  };
}

export type PrivacyLevel = 'public' | 'private';
export type ShareStep =
  // This is the initial step and is when the sender has sent the share request and is waiting for the receiver
  | 'shared'
  // This is the next step after the receiver has entered their info and the creator needs to pay
  // TODO: Make this step optional and so if the receiver pays then create a transaction for both the creator and receiver
  | 'needsPayment'
  | 'updateKnownIssues'
  // This is the final step that markes this shared transaction as done and in the books (occurs after payment and storage)
  | 'complete';
export interface IShareTransactionInfo {
  // The sender has to be a user
  // NOTE: Always get the contact info form the user account so that we use the newest (most correct) info
  senderUserId: string;
  // The receiver doesn't have to ber a user but has to have a contact email address
  // or phone number (TBD — Coming soon!)
  // Phone is TBD but should also be moved under the user account due to the note above!
  // phone?: string;
  receiverUserId?: string;
  // This is only used when the receiver doesn't have an account with us already!
  // NOTE: This will be overriden by the receiver user ID when the user creates an account
  receiverEmail?: string;
  sharingId: string;
  transactionId: string;
  privacy: EnumSharePrivacy;
  step: ShareStep;
}

export type FeeInfos = {
  regFee: number;
  taxFee: number;
  titleFee: number;
  warrantyFee: number;
  totalFee: number;
};

export type EncryptedData = {
  data: string;
}

export enum EnumUserRole {
  Buyer = 'buyer',
  Seller = 'seller',
  // SelfTransfer = 'selfTransfer'
}

export enum EnumUserType {
  Main = 'main',
  Secondary = 'secondary'
}

export enum EnumTheme {
  Dark = 'dark',
  Light = 'light'
}

export enum EnumShareStep {
  Shared = 'shared',
  NeedsPayment = 'needsPayment',
  UpdateKnownIssues = 'updateKnownIssues',
  Complete = 'complete'
}

export enum EnumShareRole {
  Receiver = 'receiver',
  Sender = 'sender'
}

export enum EnumSharePrivacy {
  Private = 'private',
  Public = 'public'
}

export interface VINResultsInfo {
  ABS: string;
  ActiveSafetySysNote: string;
  AdaptiveCruiseControl: string;
  AdaptiveDrivingBeam: string;
  AdaptiveHeadlights: string;
  AdditionalErrorText: string;
  AirBagLocCurtain: string;
  AirBagLocFront: string;
  AirBagLocKnee: string;
  AirBagLocSeatCushion: string;
  AirBagLocSide: string;
  AutoReverseSystem: string;
  AutomaticPedestrianAlertingSound: string;
  AxleConfiguration: string;
  Axles: string;
  BasePrice: string;
  BatteryA: string;
  BatteryA_to: string;
  BatteryCells: string;
  BatteryInfo: string;
  BatteryKWh: string;
  BatteryKWh_to: string;
  BatteryModules: string;
  BatteryPacks: string;
  BatteryType: string;
  BatteryV: string;
  BatteryV_to: string;
  BedLengthIN: string;
  BedType: string;
  BlindSpotIntervention: string;
  BlindSpotMon: string;
  BodyCabType: string;
  BodyClass: string;
  BrakeSystemDesc: string;
  BrakeSystemType: string;
  BusFloorConfigType: string;
  BusLength: string;
  BusType: string;
  CAN_AACN: string;
  CIB: string;
  CashForClunkers: string;
  ChargerLevel: string;
  ChargerPowerKW: string;
  CoolingType: string;
  CurbWeightLB: string;
  CustomMotorcycleType: string;
  DaytimeRunningLight: string;
  DestinationMarket: string;
  DisplacementCC: string;
  DisplacementCI: string;
  DisplacementL: string;
  Doors: string;
  DriveType: string;
  DriverAssist: string;
  DynamicBrakeSupport: string;
  EDR: string;
  ESC: string;
  EVDriveUnit: string;
  ElectrificationLevel: string;
  EngineConfiguration: string;
  EngineCycles: string;
  EngineCylinders: string;
  EngineHP: string;
  EngineHP_to: string;
  EngineKW: string;
  EngineManufacturer: string;
  EngineModel: string;
  EntertainmentSystem: string;
  ErrorCode: string;
  ErrorText: string;
  ForwardCollisionWarning: string;
  FuelInjectionType: string;
  FuelTypePrimary: string;
  FuelTypeSecondary: string;
  GCWR: string;
  GCWR_to: string;
  GVWR: string;
  GVWR_to: string;
  KeylessIgnition: string;
  LaneCenteringAssistance: string;
  LaneDepartureWarning: string;
  LaneKeepSystem: string;
  LowerBeamHeadlampLightSource: string;
  Make: string;
  MakeID: string;
  Manufacturer: string;
  ManufacturerId: string;
  Model: string;
  ModelID: string;
  ModelYear: string;
  MotorcycleChassisType: string;
  MotorcycleSuspensionType: string;
  NCSABodyType: string;
  NCSAMake: string;
  NCSAMapExcApprovedBy: string;
  NCSAMapExcApprovedOn: string;
  NCSAMappingException: string;
  NCSAModel: string;
  NCSANote: string;
  Note: string;
  OtherBusInfo: string;
  OtherEngineInfo: string;
  OtherMotorcycleInfo: string;
  OtherRestraintSystemInfo: string;
  OtherTrailerInfo: string;
  ParkAssist: string;
  PedestrianAutomaticEmergencyBraking: string;
  PlantCity: string;
  PlantCompanyName: string;
  PlantCountry: string;
  PlantState: string;
  PossibleValues: string;
  Pretensioner: string;
  RearAutomaticEmergencyBraking: string;
  RearCrossTrafficAlert: string;
  RearVisibilitySystem: string;
  SAEAutomationLevel: string;
  SAEAutomationLevel_to: string;
  SeatBeltsAll: string;
  SeatRows: string;
  Seats: string;
  SemiautomaticHeadlampBeamSwitching: string;
  Series: string;
  Series2: string;
  SteeringLocation: string;
  SuggestedVIN: string;
  TPMS: string;
  TopSpeedMPH: string;
  TrackWidth: string;
  TractionControl: string;
  TrailerBodyType: string;
  TrailerLength: string;
  TrailerType: string;
  TransmissionSpeeds: string;
  TransmissionStyle: string;
  Trim: string;
  Trim2: string;
  Turbo: string;
  VIN: string;
  ValveTrainDesign: string;
  VehicleType: string;
  WheelBaseLong: string;
  WheelBaseShort: string;
  WheelBaseType: string;
  WheelSizeFront: string;
  WheelSizeRear: string;
  Wheels: string;
  Windows: string;
}
export interface VINInfo {
  Count: number;
  Message: string;
  Results: VINResultsInfo[];
  SearchCriteria: string;
}

export type AdditionalInputProps = {
  help?: string,
  validateStatus?: any
}
