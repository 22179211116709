import { STORAGE_KEY } from 'src/utils/constants'
import { getStorageItemAsObject } from 'src/utils/localStorage'

import { AppActions } from '../rootActions'

type CreateWarrantyState = {
    Email: string,
    PhoneNumber: string,
    ZipCode: string | number,
    VINNumber: string,
    Mileage: number | null,
    PartnerID: string
  }

const defaultCreateWarrantyState: CreateWarrantyState = {
  Email: '',
  PhoneNumber: '',
  ZipCode: '',
  VINNumber: '',
  Mileage: null,
  PartnerID: ''
}

const loadedWarranty: CreateWarrantyState = getStorageItemAsObject<CreateWarrantyState>(
  STORAGE_KEY.KUAAY_CREATE_WARRANTY,
  defaultCreateWarrantyState
)

const initialCreateWarrantyState: CreateWarrantyState = { ...defaultCreateWarrantyState, ...loadedWarranty }

const createWarrantyReducer = (
  prevState: CreateWarrantyState,
  action: AppActions
): CreateWarrantyState => {
  switch (action.type) {
  case 'RESET_CREATE_WARRANTY_STATE':
    return { ...defaultCreateWarrantyState }
  default:
    return defaultCreateWarrantyState
  }
}

export type { CreateWarrantyState }

export {
  createWarrantyReducer,
  initialCreateWarrantyState,
  defaultCreateWarrantyState
}
