import React, { useContext, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuthen } from 'src/hooks/useAuthen'
import { EnumMessageType, useMessage } from 'src/hooks/useMessage'
import { useNewTransaction } from 'src/hooks/useNewTransaction'
import { useQuery } from 'src/hooks/useQuery'
import { EnumKycContext, EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumUserType } from 'src/utils/types'

export default function KycReturn() {
  const navigate = useNavigate()
  const { navigateToStep } = useNewTransaction()
  const { openMessage } = useMessage()
  const { isAuthenLoaded } = useAuthen()
  const location = useLocation()
  const query = useQuery()
  const context = useMemo(() => query.get('context'), [query])
  const transactionId = useMemo(() => query.get('transactionId'), [query])
  const globalStore = useContext(store)
  const { createTransaction } = globalStore.state

  useEffect(() => {
    const completeOnboard = async () => {
      try {
        await (
          await authApiAxiosWithRefresh()
        ).post('/kyc/onboarded')
        handleRedirect()
      } catch (err: any) {
        openMessage({
          text1: 'There was an error while verifying your identity.',
          text2: 'Please try again soon.',
          type: EnumMessageType.ERROR
        })
        handleRedirect()
      }
    }
    if (isAuthenLoaded) {
      completeOnboard()
    }
  }, [location, isAuthenLoaded])

  const handleRedirect = () => {
    switch (context) {
    case EnumKycContext.NewTransaction:
      if (createTransaction.lastParams?.step) {
        navigateToStep({
          step: createTransaction.lastParams.step,
          userType: createTransaction.lastParams.userType ?? EnumUserType.Main,
          hasBeenShared: createTransaction.lastParams.hasBeenShared,
          shareTransactionId: createTransaction.lastParams.shareTransactionId
        })
      } else {
        navigate(EnumRoute.NewTransaction)
      }
      break
    case EnumKycContext.TransactionDetail:
      navigate(`${EnumRoute.Transactions}/${transactionId}`)
      break
    case EnumKycContext.UserSetting:
      navigate(EnumRoute.Account)
      break
    default:
      break
    }
  }

  return (
    <div className="kyc-return-page" />
  )
}
