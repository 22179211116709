import { Warranty } from 'src/views/warranty'

export enum EnumRouteSearchParam {
  RedirectUrl = 'redirectUrl'
}

export enum EnumRoute {
  Home = '/',
  NewTransaction = '/transaction/new',
  MainVehicleInfo = '/transaction/main-vehicle-info',
  NeededInfo = '/transaction/needed-info',
  Account = '/account',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  ForgotPassword = '/forgot-password',
  Transactions = '/transactions',
  TransactionDetail = '/transactions/:id',
  SharedTransaction = '/shared-transactions/:id',
  ShippingAndTracking = '/shipments',
  NewShipping = '/shipments/new',
  ShipmentDetail = '/shipments/:id',
  Settings = '/settings',
  KycRefresh = '/kyc/refresh',
  KycReturn = '/kyc/return',
  PaymentTransferDetail = '/payment-transfer/:transactionId',
  PubShipmentQuote = '/pub/shipment/quote',
  DescriptionBuilder = '/description-builder',
  DescriptionConfirmation = '/description-confirmation',
  Warranty = '/warranty',
  VerifyIdentity = '/verify',
  VerifyIdentityDetails = '/verify/details/:orgName/:verificationId',
  VerifyIdentityHistory = '/verify/history/:orgName',
}

export enum EnumNewTransactionParams {
  // @TODO: REMOVE THIS WHEN VT PAPERWORK IS FIGURED OUT!
  TempStop = 'temp-stop',
  // @TODO: REMOVE THIS WHEN VT PAPERWORK IS FIGURED OUT!
  UserRoles = 'user-roles',
  ServicesSelection = 'services-selection',
  UserInformation = 'user-information',
  Kyc = 'kyc',
  MainVehicleInfo = 'main-vehicle-info',
  NeededInfo = 'needed-info',
  PaymentProtectionDisclosure = 'payment-protection-disclosure',
  SpecificVehicleInfo = 'specific-vehicle-info',
  Signature = 'signature',
  SignatureApplication = 'signature-application-info',
  SignatureOdometer = 'signature-odometer',
  NextUser = 'next-user',
  ShareConfirmation = 'share-confirmation',
  ShippingInfo = 'shipping-info',
  KnownIssuesDisclosure = 'known-issues-disclosure',
  KnownIssuesAgreement = 'known-issues-agreement',
  FeeInfo = 'fee-info',
  Complete = 'complete',
  OrderConfirm = 'order-confirm',
}

export enum EnumNewShipmentStep {
  MainInfo = 'main-info',
  Payment = 'payment',
  OrderConfirm = 'order-confirm',
}

export enum EnumNewWarrantyStep {
  MainInfo = 'main-info',
  OrderConfirm = 'order-confirm',
}

export enum EnumKycContext {
  NewTransaction = 'new-transaction',
  TransactionDetail = 'transaction-detail',
  UserSetting = 'user-setting',
}

export enum EnumIdentityContext {
  VerifyIdentity = 'verify',
  VerifyIdentityDetails = 'details',
  VerifyIdentityHistory = 'history',
}
