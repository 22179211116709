import React, { useContext, useEffect, useMemo } from 'react'
import { pdfjs } from 'react-pdf'
import {
  Navigate, Route, Routes, useLocation
} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { onAuthStateChanged } from 'firebase/auth'

import Wrapper from 'src/components/Wrapper/Wrapper'
import { useTheme } from 'src/hooks/useTheme'
import { Account, Settings } from 'src/views/account'
import Home from 'src/views/home'
import SignInPage from 'src/views/sign-in/SignIn'
import { SignUpPage } from 'src/views/sign-up'
import VerifyIdentityDetailsPage from 'src/views/verify-identity/verify-identity-details/VerifyIdentityDetails'
import VerifyIdentityHistoryPage from 'src/views/verify-identity/verify-identity-history/VerifyIdentityHistory'
import VerifyIdentityHomePage from 'src/views/verify-identity/verify-identity-home/VerifyIdentityHome'

import { getFirebaseAuth } from './firebase/firebase'
import { useAuthen } from './hooks/useAuthen'
import { useDisplay } from './hooks/useDisplay'
import { EnumRoute, EnumRouteSearchParam } from './router/types'
import { store } from './store/store'
import { EnumTransactionAction } from './store/types'
import { sendPageView } from './utils/analytics'
import { ALLOW_ANONYMOUS_USERS, STORAGE_KEY, USE_PROD_STRIPE } from './utils/constants'
import { authApiAxiosWithRefresh } from './utils/customAxios'
import { EnumTheme } from './utils/types'
import DescriptionBuilder from './views/description-builder/DescriptionBuilder'
import DescriptionConfirmation from './views/description-confirmation/DescriptionConfirmation'
import { Financing } from './views/financing'
import { ForgotPassword } from './views/forgot-password'
import { KycRefresh } from './views/kyc/kyc-refresh'
import { KycReturn } from './views/kyc/kyc-return'
import NewTransaction from './views/new-transaction/NewTransaction'
import PaymentTransferDetail from './views/payment-transfer/PaymentTransferDetail'
import { SharedTransaction } from './views/shared-transaction'
import { NewShipment } from './views/shipments/new-shipment/NewShipment'
import { PublicShipment } from './views/shipments/PublicShipment'
import { ShipmentDetailPage } from './views/shipments/ShipmentDetailPage'
import { Shipments } from './views/shipments/Shipments'
import { TransactionDetail } from './views/transactions'
import { Warranty } from './views/warranty'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const CustomSignInRedirectWithReturn = (props: any) => {
  const location = useLocation()
  const searchStr = location.pathname.indexOf('sign-in') === -1
    ? `?${
      new URLSearchParams({ [EnumRouteSearchParam.RedirectUrl]: `${location.pathname}${location.search}` }).toString()}`
    : ''

  return <Navigate to={`/sign-in${searchStr}`} {...props} />
}

function App() {
  const location = useLocation()
  const globalStore = useContext(store)
  const { theme } = useTheme()
  const { isAuthenLoaded, anonymousSignIn, isAnonymous } = useAuthen()
  const { windowWidth, windowHeight, minFormHeight } = useDisplay()
  const { pathname } = useLocation()
  const computedTheme = useMemo(() => {
    if (pathname === '/pub/shipment/quote') {
      return EnumTheme.Dark
    }
    return theme
  }, [pathname, theme])

  useEffect(() => { bootstrapApp() }, [])

  useEffect(() => {
    // Send page view when the route changes
    sendPageView(location.pathname)
  }, [location])

  useEffect(() => {
    secureLocalStorage.setItem(STORAGE_KEY.KUAAY_CREATE_TRANSACTION, globalStore.state.createTransaction)
  }, [globalStore.state.createTransaction])

  useEffect(() => {
    secureLocalStorage.setItem(STORAGE_KEY.KUAAY_CREATE_SHIPMENT, globalStore.state.createShipment)
  }, [globalStore.state.createShipment])

  useEffect(() => {
    document.body.setAttribute('theme', computedTheme)
  }, [computedTheme])

  const fetchStripePublicKey = async (
    retry: boolean = false
  ): Promise<undefined | string> => {
    try {
      const publicKeyResponse = await (
        await authApiAxiosWithRefresh()
      ).post('/payment/publishable-key', {
        prod: USE_PROD_STRIPE
      })
      globalStore.dispatch({
        type: 'UPDATE_STRIPE_PUBLISHABLE_KEY',
        stripePublishableKey: publicKeyResponse.data
      })
      return publicKeyResponse.data
    } catch (err) {
      if (retry) {
        // eslint-disable-next-line no-console
        console.error('Failed to stripe public key! RETRYING!', err)
        return fetchStripePublicKey(false)
      }
      // eslint-disable-next-line no-console
      console.error('Failed to stripe public key!', err)
      return undefined
    }
  }

  const bootstrapApp = async () => {
    globalStore.dispatch({
      type: 'TYRING_LOADING'
    })

    try {
      const auth = getFirebaseAuth()

      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken(true)
          globalStore.dispatch({
            type: 'SIGN_IN',
            user,
            token,
            refreshToken: user.refreshToken,
            isAnonymous: user.isAnonymous
          })
        } else {
          await anonymousSignIn()
        }

        globalStore.dispatch({
          type: 'DONE_TYRING_LOADING'
        })

        fetchStripePublicKey()

        if (pathname !== '/account') {
          globalStore.dispatch({
            type: EnumTransactionAction.FetchPreviousTransactions
          })
          globalStore.dispatch({
            type: EnumTransactionAction.FetchPendingTransactions,
            store: globalStore
          })
        }
      })
    } catch (error) {
      console.error('Error during authentication state check:', error)
      globalStore.dispatch({
        type: 'DONE_TYRING_LOADING'
      })
    }
  }

  const appStyle = useMemo(() => ({
    '--app-width': `${windowWidth}px`,
    '--app-height': `${windowHeight}px`,
    '--min-form-height': `${minFormHeight}px`
  } as React.CSSProperties), [windowHeight, windowWidth, minFormHeight])

  return (
    <Wrapper style={appStyle}>
      {/* <Button */}
      {/*  color={theme === EnumTheme.Dark ? 'secondary' : 'primary'} */}
      {/*  className="theme-switch-btn" */}
      {/*  icon={<FormatPainterOutlined />} */}
      {/*  onClick={changeTheme} */}
      {/* /> */}
      {
        isAuthenLoaded ? (
          <Routes>
            {
              (!ALLOW_ANONYMOUS_USERS && isAnonymous)
                ? (
                  <>
                    <Route path={EnumRoute.PubShipmentQuote} element={<PublicShipment />} />
                    <Route path="/sign-in" element={<SignInPage />} />
                    <Route path="/sign-up" element={<SignUpPage />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route
                      path="*"
                      element={
                        <CustomSignInRedirectWithReturn />
                      // <Navigate to="/sign-in" />
                      }
                    />
                  </>
                )
                : (
                  <>
                    <Route path="/" element={<Home />} />
                    <Route path="/account" element={<Account />} />
                    <Route path={EnumRoute.NewTransaction} element={<NewTransaction />} />
                    <Route path={EnumRoute.ShippingAndTracking} element={<Shipments />} />
                    <Route path={EnumRoute.NewShipping} element={<NewShipment />} />
                    {/* <Route path={EnumRoute.Warranty} element={<Warranty />} /> */}
                    <Route path={EnumRoute.ShipmentDetail} element={<ShipmentDetailPage />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/sign-in" element={<SignInPage />} />
                    <Route path="/sign-up" element={<SignUpPage />} />

                    <Route path="/verify" element={<VerifyIdentityHomePage />} />
                    <Route path="/financing" element={<Financing />} />
                    <Route path={EnumRoute.VerifyIdentityDetails} element={<VerifyIdentityDetailsPage />} />
                    <Route path={EnumRoute.VerifyIdentityHistory} element={<VerifyIdentityHistoryPage />} />

                    <Route
                      path={EnumRoute.TransactionDetail}
                      element={<TransactionDetail />}
                    />
                    <Route
                      path={EnumRoute.PaymentTransferDetail}
                      element={<PaymentTransferDetail />}
                    />
                    <Route
                      path={EnumRoute.SharedTransaction}
                      element={<SharedTransaction />}
                    />
                    <Route path={EnumRoute.KycRefresh} element={<KycRefresh />} />
                    <Route path={EnumRoute.KycReturn} element={<KycReturn />} />
                    <Route path={EnumRoute.PubShipmentQuote} element={<PublicShipment />} />
                    <Route path="/description-builder" element={<DescriptionBuilder />} />
                    <Route path={EnumRoute.DescriptionConfirmation} element={<DescriptionConfirmation />} />
                  </>
                )
            }
          </Routes>
        ) : (
          <Routes>
            <Route path={EnumRoute.PubShipmentQuote} element={<PublicShipment />} />
          </Routes>
        )
      }
    </Wrapper>
  )
}

export default App
